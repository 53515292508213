<template>
  <div class="warp">
    <el-container>
      <side />
      <el-container>
        <el-header class="head">
          <myhead></myhead>
        </el-header>
        <el-main>
          <transition name="el-fade-in-linear">
            <!-- <keep-alive> -->
            <router-view />
            <!-- </keep-alive> -->
          </transition>
        </el-main>
        <el-footer class="footer">
          <myfooter></myfooter>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import myhead from '@/components/head'
import side from '@/components/side'
import myfooter from '@/components/footer'

export default {
  components: {
    side,
    myhead,
    myfooter,
  },
}
</script>
<style lang="scss" scoped>
.warp {
  position: relative;
  min-width: 1340px;
  padding-top: 60px;
}
.head {
  position: fixed;
  background: #fff;
  width: 100%;
  top: 0;
  z-index: 111;
}
.el-main {
  padding: 0;
}
.el-footer {
  height: 280px !important;
  background-color: #f5c02f;
}
</style>

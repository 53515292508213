<template>
  <div class="aside">
    <div class="list">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="item.text"
        @click="onClickItem(index)"
      >
        <div class="icon">
          <img :src="item.icon" />
        </div>
        <span class="text">{{ item.text }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      list: [
        {
          text: 'APP',
          icon: require('../../images/aside/app.png'),
          name: 'app',
        },
        {
          text: '微信',
          icon: require('../../images/aside/wechat.png'),
          name: 'wechat',
        },
        {
          text: '小程序',
          icon: require('../../images/aside/miniprogram.png'),
          name: 'minigrogram',
        },
        {
          text: 'ERP',
          icon: require('../../images/aside/ERP.png'),
          name: 'system',
        },
        {
          text: '网站',
          icon: require('../../images/aside/website.png'),
          name: 'website',
        },
      ],
    }
  },
  computed: mapState(['menu']),
  watch: {
    menu(newVal) {
      for (let i = 0; i < this.list.length; i++) {
        for (let j = 0; j < newVal.length; j++) {
          if (this.list[i].name === newVal[j].name) {
            this.list[i].id = newVal[j].id
          }
        }
      }
    },
  },
  methods: {
    onClickItem(index) {
      const { name, id } = this.list[index]
      this.$router.push({
        name,
        query: {
          navId: id,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '../../styles/var.scss';

.aside {
  background-color: $saffron;
  position: fixed;
  z-index: 999999;
  right: 0;
  // top: 300px;
  top: 30%;
  transform: translateY(-30%);
  width: 62px !important;
  box-shadow: -1px 2px 3px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 15px 0 8px;
    position: relative;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 12px 0;
      cursor: pointer;
      .icon {
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .text {
        font-size: 12px;
        font-weight: bold;
        color: #f4f5f9;
      }
    }
  }
}
</style>

<template>
  <div class="footer">
    <div class="top">
      <img class="icon" src="../../images/arrow.png" @click="toTop" />
      <span class="text" @click="toTop">回到顶部</span>
    </div>
    <div class="box">
      <div class="left">
        <div class="item" v-for="(item, index) in list" :key="index">
          <span class="title">{{ item.title }}</span>
          <ul>
            <li v-for="(subItem, subIndex) in item.subList" :key="subIndex">
              {{ subItem }}
            </li>
          </ul>
        </div>
      </div>
      <div class="center">
        <img class="qrcode" src="../../images/qrcode.png" alt="微信二维码" />
        <p>扫二维码</p>
        <p>了解更多信息</p>
      </div>
      <div class="right">
        <div class="mobile">
          <img class="icon" src="../../images/mobile.png" alt="电话" />
          <span class="text">15088099973</span>
        </div>
        <img class="logo" src="../../images/footer-logo.png" />
        <!-- <p class="company">广州哼哈信息科技有限公司</p> -->
        <div class="descs">
          <p class="desc">2019 SKINNY SHIPS,UC.ALL.RIGHTS RESERVED</p>
          <p class="desc">广州哼哈信息科技有限公司 备案号：粤ICP备18136192号</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          title: '首页',
          subList: [
            '电商   解决方案',
            'APP开发  解决方案',
            '智能硬件  解决方案',
            'OA/CRM系统  解决方案',
            '美食行业  解决方案',
          ],
        },
        {
          title: '案例',
          subList: ['APP开发案例', '微信开发案例', '企业网站案例', '其他案例'],
        },
      ],
    }
  },
  methods: {
    toTop() {
      window.scrollTo(0, 0)
    },
  },
}
</script>

<style lang="scss">
@import '../../styles/var.scss';
.footer {
  box-sizing: border-box;
  height: 100%;
  padding: 0 8%;
  .top {
    padding-top: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon {
      width: 20px;
      height: 12px;
      cursor: pointer;
    }
    .text {
      font-size: 12px;
      font-weight: 500;
      color: $ship-gray;
      cursor: pointer;
    }
  }
  .box {
    display: flex;
    margin: 15px auto 0;
    justify-content: center;
    position: relative;
    .left {
      display: flex;
      position: absolute;
      left: 0;
      .item {
        text-align: left;
        min-width: 150px;
        margin-right: 50px;
        .title {
          font-size: 16px;
          font-weight: 500;
          color: $ship-gray;
          position: relative;

          &::after {
            display: block;
            content: '';
            position: absolute;
            left: 0;
            bottom: -5px;
            width: 21px;
            height: 3px;
            background-color: $ship-gray;
          }
        }
        ul {
          display: flex;
          flex-direction: column;
          padding-top: 11px;
          li {
            color: $ship-gray;
            padding: 3px 0;
          }
        }
      }
    }
    .center {
      display: flex;
      flex-direction: column;
      .qrcode {
        width: 91px;
        height: 91px;
      }
      p {
        font-size: 12px;
        color: $ship-gray;
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      position: absolute;
      right: 0;
      .mobile {
        display: inline-flex;
        align-items: center;
        padding: 9px 14px;
        border-radius: 18px;
        background: #fff;
        line-height: 1;
        .icon {
          width: 17px;
          height: 21px;
          margin-right: 10px;
        }
        .text {
          font-size: 20px;
          font-weight: bold;
          color: $saffron;
        }
      }
      .logo {
        width: 109px;
        height: 23px;
        margin-top: 28px;
      }
      .company {
        font-weight: bold;
        color: $ship-gray;
        margin-top: 7px;
      }
      .descs {
        margin-top: 25px;
        color: $ship-gray;
        font-size: 12px;
      }
    }
  }
}
</style>

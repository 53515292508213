<template>
  <div class="headerWrap">
    <img class="left" @click="goHome" src="../../images/logo.png" alt="logo" />
    <div class="center">
      <ul>
        <li v-for="item in menu" :key="item.name">
          <router-link
            :to="{ name: item.name, query: { navId: item.id } }"
            exact
            >{{ item.mata }}</router-link
          >
        </li>
      </ul>
    </div>
    <!-- <div class="right">
      <span class="text">联系我们：</span>
      <img class="wechat" src="../../images/wechat.png" alt="微信" />
      <img class="qq" src="../../images/qq.png" alt="qq" />
    </div> -->
    <div style="width: 145px"></div>
  </div>
</template>
<script>
import { nav } from '@/api/common'
export default {
  data() {
    return {
      menu: [],
    }
  },
  created() {
    this.getNav()
  },
  methods: {
    goHome() {
      this.$router.replace({
        name: 'home',
      })
    },
    async getNav() {
      let { data } = await nav()
      if (data) {
        data.forEach((item) => {
          item.name = item.path.slice(1)
        })
      }
      this.menu = data
      this.$store.commit('setMenu', data)
    },
  },
}
</script>

<style lang="scss" scope>
@import '../../styles/var.scss';
.headerWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 1340px;
  margin: 0 auto;
  .left {
    width: 129px;
    height: 28px;
    cursor: pointer;
  }
  .center {
    ul {
      display: flex;
      li {
        font-size: 16px;
        font-weight: 500;
        color: #959595;
        padding: 0 16px;
        position: relative;
        & .router-link-active {
          color: $saffron;
          &::after {
            display: block;
            content: '';
            position: absolute;
            left: 50%;
            bottom: -4px;
            transform: translateX(-50%);
            width: 20px;
            height: 4px;
            background-color: $saffron;
            border-radius: 2px;
          }
        }
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    background-color: $saffron;
    padding: 0 14px;
    height: 30px;
    border-radius: 15px;
    justify-content: center;
    .text {
      font-size: 14px;
      font-weight: 500;
      color: #565656;
    }
    .wechat {
      width: 21px;
      height: 17px;
      margin-right: 10px;
    }
    .qq {
      width: 16px;
      height: 17px;
    }
  }
}
</style>
